import React from 'react'
import SEO from 'components/seo';

import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContactMe from 'components/ContactMe' ;
import Article from 'components/Article';

const supportPolicy = () => {
    return (
        <Layout pageName="support-policy">
        <SEO title="Support Policy" />
        <Hero title="Support Policy"></Hero>
        <Article>

        <div className="entry-text">
        <p> Themedost offer a free one to one product support for all the customers. The support is email based and you can find the contact form here to send in the support requests. My effort is to respond to your support requests within 24 hrs. But please bear with me if it is a weekend. When you contact me for support request please provide as much as information to describe your issue. Please remember to include details to verify your purchase as well. The date of purchase and the email id for purchase should suffice.</p>

        <h3> What is covered in product support? </h3>
        <p>The templates for osTicket are basically customisations made to the client section of the osTicket software. So any issue related to these customised files are covered under product support. That includes and not limited to the following</p>
        <ul>
            <li> Layout issues on client pages </li>
            <li> Style issues on client pages</li>
            <li> Form issues on client pages </li>
            <li> Installation problems </li>
            <li> Help with issues not covered in documentation</li>
            <li> Help with issues that prevents the template to function as advertised on the preview  </li>
        </ul>

        <h3> What is not covered in product support? </h3>

        <p>Issues that are related to the core files and functions of the osTicket is not included in the template support. The modified template files does not include the admin section of the osTicket. The issues related to admin section is also not included in the theme support. Few other issues that are not included in the support are</p>
        <ul> 
            <li> Deep customisation to the template </li>  
            <li> Adding additional functionalities to the template</li> 
            <li> Issues arising as a result of customisation done by the customer</li> 
            <li> 3rd party scripts compatibility issues </li> 
        </ul>


        </div>
        </Article>
        <ContactMe></ContactMe>
        </Layout>
    )
}

export default supportPolicy
